import React from 'react';
import PropTypes from 'prop-types';

import { testAttributes } from '../Constants';
import Breadcrumb from '../../../simple/Breadcrumb';

import {breadcrumbContainer, mortgageToolEditorial} from '../MortgageToolPage.module.scss';

const ToolPageHeader = ({ title, breadcrumbs, children }) => 
    (
        <>
            <div className={breadcrumbContainer}>
                <Breadcrumb
                    breadcrumbs={breadcrumbs}
                    data-test={testAttributes.TOOLPAGE_BREADCRUMBS}
                    className="breadcrumb"
                />
            </div>
            <div data-test={testAttributes.TOOLPAGE_CONTENTBEFORE} className={mortgageToolEditorial}>
                <h1>{title}</h1>
                {children}
            </div>
        </>
    )
;

ToolPageHeader.propTypes = {
    /** title, Header title for GraphQL  */
    title: PropTypes.string,
    /** toolPage, data for GraphQL  */
    breadcrumbs: PropTypes.arrayOf(PropTypes.any).isRequired,
    /** Children, Component trigger the AuthorContent */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
};

ToolPageHeader.defaultProps = {
    title: '',
    children: '',
};

export default ToolPageHeader;
