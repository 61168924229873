import * as HeaderConstants from '../../composite/Header/Constants';
import * as FooterConstants from '../../composite/Footer/Constants';
import * as GridContainerConstants from '../../composite/GridContainer/Constants';
import * as ArticleSourceConstants from '../../simple/ArticleSource/Constants';

import {fnParseWith} from '../../../utilities/parses';
import { fnFootnoteParser } from '../../../templates/PostContainer/helpers/functionsParse';

import sampleHTML from './stories/EditorialSample/sampleHTML';

export const testAttributes = {
    TOOLPAGE_MAIN: 'toolpage_main',
    TOOLPAGE_BREADCRUMBS: 'toolpage_breadcrumbs',
    TOOLPAGE_GRIDCONTAINER: 'toolpage_gridcontainer',
    TOOLPAGE_TITLREADMORE: 'toolpage_titlereadmore',
    TOOLPAGE_CONTENTBEFORE: 'toolpage_contentBefore',
    TOOLPAGE_CONTENTAFTER: 'toolpage_contentAfter',
};

const contentAfter = `<h2>More editorial content</h2><p>Something to explain the calculations, and perhaps go into some detail on the different loan types. This area needs to be able to handle varying lengths of content. </p><h2>Another sub-head, perhaps?</h2><p>
Why not indeed Nothing better than an attractive sub-header. Really draws the readers’ attention. <span class="cdm-footnote-container">Eu cillum mentitum adipisicing in do duis te sunt, doctrina
praetermissum sed admodum, offendit minim labore admodum esse. Irure
laboris coniunctione, elit ingeniis an quae tempor, do duis
exquisitaque, admodum nisi multos hic quis id se laboris firmissimum
quo eiusmod quid noster admodum tamen, quo ea dolore magna quorum a
ut elit aliquip firmissimum Article Source: <a href="https://yahoo.com">https://yahoo.com</a> - credit here. </span></p>`;

// eslint-disable-next-line no-unused-vars
const [responseParse, postContent] = fnParseWith(contentAfter, {
    footnoteParser: fnFootnoteParser
});

export const sampleAttributes = {
    databaseId: 1111,
    infoPost: {
        title: 'Refinance Mortgage Calculator',
        contentBefore: `<h2>Find the Right Refinance Option.</h2><p>Calculate your new monthly payment, interest rate, and potential across a number of different loan types </p>`,
        content: sampleHTML,
        contentAfter: postContent
    },
    breadcrumbs: [
        {
            id: 10802,
            term_id: null,
            text: 'Home',
            url: 'https://dev.lowermybills.com/',
        },
        {
            id: 11802,
            term_id: null,
            text: 'Mortgage Tools',
            url: 'https://dev.lowermybills.com/mortgage-tools/',
        },

        {
            id: 14984,
            term_id: null,
            text: 'Refinance Mortgage Calculator',
            url:
                'https://dev.lowermybills.com/mortgage-tools/refinance-mortgage-calculator/',
        },
    ],
    sharebutton: {
        data: [
            'Facebook',
            'Twitter',
            'Linkedin',
        ],
        url: 'https://www.google.com',
    },
    postsRelated: GridContainerConstants.gridParams.samplePosts,
    articleSourceList: ArticleSourceConstants.dataSample.sourceList,
    headerProps: {
        headerMenu: HeaderConstants.sampleAttributes.headerMenu,
        logoUrl: HeaderConstants.defaultAttributes.url,
        logoAlt: HeaderConstants.defaultAttributes.alt,
    },
    footerProps: {
        itemsMenu: FooterConstants.sampleAttributes.listMenu,
        socialLinks: FooterConstants.sampleAttributes.links,
    },
    labelBtn: 'SEE MORE',
    gridLabel: 'Read More',
};