import { defaultParentTitle } from '../Constants';

const getMortgageToolBreadcrumbs = (breadcrumb, parentPage) => 
{
    const parentCrumb = parentPage
        ? {
              id: parentPage.databaseId,
              term_id: null,
              text: parentPage.title,
              url: parentPage.uri,
          }
        : {
              id: null,
              term_id: null,
              text: defaultParentTitle,
              url: null,
          };
    return [[...breadcrumb].shift(), parentCrumb, [...breadcrumb].pop()];
};
export default getMortgageToolBreadcrumbs;
