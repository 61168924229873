import React from 'react';
import PropTypes from 'prop-types';

import ToolPageHeader from './ToolPageHeader';
import GridContainer from '../../imported/GridContainer';
import ArticleSource from '../../simple/ArticleSource';
import parseHTML from '../../../utilities/parseHTML';
import { articleSourcesId } from '../../composite/Footnote/Constants';
import { testAttributes } from './Constants';
import { build } from '../../../Constants.json';


import {
    PostComponent,
    content,
    titlePostRelated,
    mortgageToolEditorial,
    separator,
    belowTool
} from './MortgageToolPage.module.scss';

export const fngetMorePosts = (posts, index, newIndex) =>
    posts.slice(index, newIndex);
const MortgagePage = ({ gridTitle, postInfo }) => 
{
    const fnValidateContent = (data) =>
        typeof data === 'string' ? parseHTML(data) : data;

    return (
        <div
            itemProp="articleBody"
            className={`${PostComponent} site-content ${content} `}
            id="primary"
            data-test={testAttributes.TOOLPAGE_MAIN}
        >
            <ToolPageHeader title={postInfo.infoPost.title} breadcrumbs={postInfo.breadcrumbs}>
                {fnValidateContent(postInfo.infoPost.contentBefore)}
            </ToolPageHeader>
            <hr className={separator} />
            {postInfo.infoPost.content}
            <hr className={`${separator} ${belowTool}`} />
            <div data-test={testAttributes.TOOLPAGE_CONTENTAFTER} className={mortgageToolEditorial}>
                {fnValidateContent(postInfo.infoPost.contentAfter)}
                {postInfo.articleSourceList?.length ? (
                        <ArticleSource
                            id={articleSourcesId}
                            sourceList={postInfo.articleSourceList}
                        />
                ) : null}
            </div>
            <h2
                data-test={testAttributes.TOOLPAGE_TITLREADMORE}
                className={titlePostRelated}
            >
                {gridTitle}
            </h2>
            <GridContainer
                queryArgs={{
                    posts: postInfo.postsRelated,
                    total_found: postInfo.postsRelated.length,
                }}
                type="button"
                gridLimit={build.defaults.POSTS_PER_PAGE}
                getMorePosts={fngetMorePosts}
                btnLabel={postInfo.btnLabel}
                data-test={testAttributes.TOOLPAGE_GRIDCONTAINER}
                containerId={build.defaults.GRID_CONTAINER_ID}
            />
        </div>
    )
}

MortgagePage.propTypes = {
    /** postInfo, data from GraphQL  */
    postInfo: PropTypes.objectOf(PropTypes.any).isRequired,
    /** title, for grid container default Read More  */
    gridTitle: PropTypes.string,
};

MortgagePage.defaultProps = {
    gridTitle: testAttributes.gridLabel,
};

export default MortgagePage;