/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../../components/composite/Layout';
import Seo from '../../components/simple/seo';
import MortgageToolPage from '../../components/pages/MortgageToolPage';

import { bodyClass, bodyClassPrefix, titleRelated } from './Constants';
import processQueried from './helpers/processQueried';
import parseLayoutProps from '../common/parse/parseLayoutProps';
import useLocation from '../../utilities/useLocation'

/* following imports are GraphQL fragments, they are used within the page query */
import {
    menuNodes,
    menuItemNodes,
    postCardData,
    siteMetadata,
    mortgageData,
    loanTypesData,
} from '../../utilities/fragments';

const MortgageToolContainer = ({ data, location }) => 
{
    useLocation( location );
    const { footerProps, headerProps } = parseLayoutProps(data, location);
    const { postArgs, headArgs } = processQueried(data);
    return (
        <Layout headerProps={headerProps} footerProps={footerProps}>
            <Seo
                helmetProps={headArgs}
                bodyClass={`${bodyClass} ${bodyClassPrefix}${data.post?.databaseId}`}
            />
            <MortgageToolPage gridTitle={titleRelated} postInfo={postArgs} />
        </Layout>
    );
};

export default MortgageToolContainer;

MortgageToolContainer.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const pageQuery = graphql`
    query MortgagePostById($id: String!) {
        site {
            ...siteMetadata
        }
        post: wpMortgageTool(id: { eq: $id }) {
            ...mortgageData
        }
        loand: allWpLmbLoanType {
            nodes {
                ...loanTypesData
            }
        }
        posts: allWpPost {
            edges {
                node {
                    ...postCardFields
                }
            }
        }
        guides: allWpGuide {
            edges {
                node {
                    ...guideCardFields
                }
            }
        }
        parentPage: wpPage(slug: { eq: "mortgage-tools" }) {
            databaseId
            title
            uri
        }
        headerMenuNav: allWpMenuItem(
            filter: { locations: { eq: MENU_1 }, parentDatabaseId: { eq: 0 } }
        ) {
            ...menuItemNodes
        }
        footerMenuPolicy: wpMenu(locations: { eq: MENU_2 }) {
            menuItems {
                ...menuNodes
            }
        }
        footerMenuCol1: wpMenu(locations: { eq: FOOTER_COL_1 }) {
            menuItems {
                ...menuNodes
            }
        }
        footerMenuCol2: wpMenu(locations: { eq: FOOTER_COL_2 }) {
            menuItems {
                ...menuNodes
            }
        }
    }
`;
