// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var content = "h_h";
export var breadcrumbContainer = "h_j";
export var breadcrumb = "h_k";
export var PostComponent = "h_l";
export var titlePostRelated = "h_m";
export var mortgageToolEditorial = "h_t";
export var separator = "h_v";
export var belowTool = "h_w";