import React from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { fnParseWith } from '../../../utilities/parses';
import { fnFootnoteParser } from '../../PostContainer/helpers/functionsParse';
import { filterAndSortRelatedPosts } from '../../../utilities/gridCardFilters';
import processHeadArgs from '../../common/process/processHeadArgs';
import getMortgageToolBreadcrumbs from './getMortgageToolBreadcrumbs';
import { titleRelated, btnLabel, toolContainerClassName } from '../Constants';

const processPostArgs = (queried) => 
{
    const postArgs = {
        titleRelated,
        btnLabel,
        listLinks: [],
    };

    const { post, posts, guides } = queried;
    const listPosts = [...posts?.edges, ...guides?.edges];

    postArgs.postsRelated = filterAndSortRelatedPosts(0, listPosts, []);

    const breadcrumbs = getMortgageToolBreadcrumbs(
        post.cdmfields.breadcrumb,
        queried.parentPage
    );

    postArgs.breadcrumbs = breadcrumbs.map((crumb) => ({
        id: crumb.id,
        term_id: crumb.term_id,
        text: DOMPurify.sanitize(crumb.text),
        url: DOMPurify.sanitize(crumb.url),
    }));

    return postArgs;
};

export const splitContent = (content) => 
{
    const index = content.findIndex(
        (item) => item?.props?.className === toolContainerClassName
    );

    return {
        contentBefore: content.slice(0, index - 1),
        content: index !== -1 ? content[index] : '',
        contentAfter: content.slice(index + 1, content.length),
    };
};

export const contentExceptions = (content, loand) => 
{
    if (
        !content?.props?.['data-toolattributes']?.includes('mortgageComparison')
    )
        return content;
    const resultsFields = loand || {};
    return React.cloneElement(content, {
        ...content.props,
        children: React.cloneElement(content.props.children, {
            ...content.props.children.props,
            resultsFields,
        }),
    });
};

const processQueried = (queried, additionalParsers = {}) => 
{
    const { metadata } = queried.post.cdmfields;

    const [responseParse, postContent] = fnParseWith(queried.post.content, {
        ...additionalParsers,
        footnoteParser: fnFootnoteParser,
    });

    const contentObject = splitContent(postContent);
    if (contentObject?.content && queried.loand) 
    {
        contentObject.content = contentExceptions(
            contentObject.content,
            queried.loand
        );
    }

    const listArticles = responseParse.footnoteParser.return.articleSourceList();

    const allArgs = {
        headArgs: processHeadArgs(metadata, queried.site.siteMetadata),
        postArgs: {
            ...processPostArgs(queried),
            infoPost: {
                title: DOMPurify.sanitize(queried.post.title || ''),
                ...contentObject,
            },
            articleSourceList: listArticles,
        },
    };

    return allArgs;
};

export default processQueried;
